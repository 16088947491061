<template>
	<div class="half_layer_wrap">
		<div
			class="half_layer_wrap_inner"
		>
			<article class="half_view">
				<div class="layer_title">
					<h3>{{  $language.payment.method }} <i class="icon icon-close" @click="$emit('cancel')"></i></h3>
				</div>
				<div class="hlayer">
					<div class="create_wallet">
						<ul>
							<li
								v-for="(currency, index) in currencies"
								:key="'currency_' + index"
								@click="$emit('click', currency)"
							>
								<button>
									{{ currency.label }}
<!--									<span v-if="currency.dicntrate" class="Blue01">{{ currency.sl_coin_token_quantity }} <span style="color: #DC505C">({{ currency.dicntrate }}% DC)</span></span>-->
<!--									<span v-else class="Blue01">{{ currency.dc_before_sl_coin_token_quantity }}</span>-->
								</button>

							</li>
							<li
								v-if="is_krw"
								@click="$emit('credit')"
							>
								<button>
									{{  $language.common.credit_card }}
<!--									<span v-if="legal.payment_mthd_sl_price_dicntrate" class="Blue01">{{ legal.payment_mthd_sl_price }} <span style="color: #DC505C">({{ legal.payment_mthd_sl_price_dicntrate }}% DC)</span></span>-->
<!--									<span v-else class="Blue01">₩ {{ krw_price }}</span>-->
								</button>
							</li>
						</ul>
					</div>
				</div>
			</article>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'mafia03911'
		, props: ['currencies', 'legal']
		, data: function(){
			return {

			}
		}
		, computed: {
			is_krw: function(){
				let t = false

				if(this.legal.sl_leglter_div_code == 'BC00800001'){
					t = true
				}

				return t
			}
			, krw_price: function(){
				let t = this.legal.payment_mthd_sl_price
				let d = true
				if(d){
					t *= 1.1
				}
				return t
			}
		}
		, methods: {

		}
		, created() {
		}
	}
</script>

<style>
	.layer_title h3 {
		display: flex !important; justify-content: space-between;
	}
	.create_wallet li button {
		display: flex !important; justify-content: space-between;
	}

	.Blue01 {
		color: #3E47B7;
		font-weight: 600;
	}
</style>